var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root-base-field"},[_c('div',{staticClass:"base-field",class:{
    'not-empty': _vm.notEmpty,
    'base-field-theme-normal': _vm.theme === 'normal',
    'base-field-theme-dark': _vm.theme === 'dark',
    'base-field-theme-light': _vm.theme === 'light',
    'base-field-theme-transparent': _vm.theme === 'transparent',
    'base-field-readonly': _vm.readonly,
    'base-field-theme-modern': _vm.theme === 'modern',
    'base-field-theme-modern-grey': _vm.theme === 'modern-grey',
    'base-field-theme-modern-no-animation': _vm.theme === 'modern-no-animation'
  }},[_c('input',{ref:"baseFieldInput",attrs:{"id":_vm.key,"type":_vm.type,"required":_vm.required,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"input":_vm.onInputHandler,"paste":_vm.keyPressHandler,"keypress":[_vm.keyPressHandler,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.keyPressEnterHandler($event)}],"blur":_vm.blurHandlerFn}}),_c('label',{attrs:{"htmlFor":_vm.key}},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"right"},[_vm._t("right")],2),(_vm.underlineText)?_c('span',{staticClass:"underline-text",class:{
        'underline-info-type': _vm.underlineType === 'info',
        'underline-normal-type': _vm.underlineType === 'normal'
      }},[_vm._v(_vm._s(_vm.underlineText))]):_vm._e(),_c('div',{staticClass:"bottom"},[_vm._t("bottom")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }