import NavBar from '@/pages/lib/sections/NavBar/NavBar.vue'
import PublicFooter from '@/pages/lib/sections/PublicFooter/PublicFooter.vue'

import HeaderSection from './HeaderSection/HeaderSection.vue'
import CategoryNavSection from './CategoryNavSection/CategoryNavSection.vue'
import RecentPostsSection from './RecentPostsSection/RecentPostsSection.vue'
import SubscribeSection from './SubscribeSection/SubscribeSection.vue'

export default {
  components: {
    NavBar,
    HeaderSection,
    CategoryNavSection,
    RecentPostsSection,
    SubscribeSection,
    PublicFooter
  }
}
