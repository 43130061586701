import { mapActions, mapGetters, mapState } from 'vuex'
import { CachedImageModel } from 'src/models'

import Spinner from '@/components/lib/Spinner/Spinner.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'
import TxInlineSvg from '@/components/lib/TxInlineSvg/TxInlineSvg.vue'

import RecentPostTile from './RecentPostTile/RecentPostTile.vue'

export default {
  components: {
    Spinner,
    TxButton,
    RecentPostTile,
    TxInlineSvg
  },
  props: {
    simpleMode: Boolean,
    title: String
  },
  data () {
    return {
      category: this.$route.params.category || null,
      tags: this.$route.query.tags || null,
      query: this.$route.query.query || null,
      isArticlesMoreLoading: false
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    }),
    ...mapState({
      articles: state => state.blog.shortArticles,
      DEFAULT_ARTICLES_PAGE_SIZE: state => state.blog.DEFAULT_ARTICLES_PAGE_SIZE,
      totalPages: state => state.blog.totalPages,
      page: state => state.blog.page,
      socials: state => state.public.socials
    }),
    articlesItems () {
      return this.articles.value
    },
    isCanLoadMoreArticles () {
      return this.page < (this.totalPages - 1)
    },
    isArticlesLoading () {
      return this.articles.isLoading
    },
    sortedSocials () {
      const socials = [...this.socials]
      return socials
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .filter(socials => socials.showInMainPageBlog)
    }
  },
  methods: {
    ...mapActions({
      _loadMoreArticles: 'blog/loadArticles'
    }),
    async loadMoreArticles () {
      this.isArticlesMoreLoading = true
      await this._loadMoreArticles({
        page: this.page + 1,
        append: true
      })
      this.isArticlesMoreLoading = false
    },
    goToTg () {
      window.open('https://t.me/timexioru', '_blank')
    },
    goToBlog () {
      this.$router.push({
        path: this.createLocalizedLink('/blog')
      })
    },
    createLocalizedLink (url) {
      if (url.startsWith('/private')) {
        return url
      }
      return this.getLanguageUrlPathPrefix() + url
    },
    getImageUrl (image) {
      const imageModel = CachedImageModel.fromServerModel(image, {
        baseUrl: this.config.publicService.imageUrl
      })
      return imageModel.url
    }
  }
}
