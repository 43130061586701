import { mapState, mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { oneOf } from 'src/utils'

import TxButton from '@/components/lib/TxButton/TxButton.vue'
import BaseField from '@/components/lib/BaseField/BaseField.vue'
// import MessageModal from '@/modals/lib/MessageModal/MessageModal'

export default {
  components: {
    TxButton,
    BaseField
  },
  data () {
    return {
      email: '',
      isSubscribed: false
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  computed: {
    ...mapState({
      passport: state => state.passport?.user
    })
  },
  methods: {
    oneOf,
    ...mapActions({
      blogSubscribe: 'blog/blogSubscribe',
      openModal: 'modals/open'
    }),
    async handleSend () {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      this.isSubscribed = true

      await this.blogSubscribe({
        email: this.email
      })

      this.email = ''
    }
  }
}
