import { mapState } from 'vuex'
import TwoColumnHeaderPartial from '@/pages/lib/partials/TwoColumnHeaderPartial/TwoColumnHeaderPartial.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

export default {
  components: {
    TwoColumnHeaderPartial,
    TxButton
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      passport: state => state.passport?.user
    }),
    promoContent () {
      return {
        title: this.i18nSection('blogpageHeaderTitle'),
        description: this.i18nSection('blogpageHeaderDescription'),
        subtitle: this.i18nSection('blogpageHeaderSubtitle')
      }
    }
  },
  methods: {
  }
}
