import { mapState, mapActions } from 'vuex'
import BlogMainPage from '../BlogMainPage/BlogMainPage.vue'
import NotFound from '../../NotFound/NotFound.vue'

export default {
  data () {
    return {
      category: this.$route.params.category || null,
      tags: this.$route.query.tags || null,
      query: this.$route.query.query || null,
      initialPage: this.$route.query.page || 0
    }
  },
  computed: {
    ...mapState({
      DEFAULT_ARTICLES_PAGE_SIZE: state => state.blog.DEFAULT_ARTICLES_PAGE_SIZE,
      totalPages: state => state.blog.totalPages,
      page: state => state.blog.page,
      articles: state => state.blog.shortArticles
    }),
    blogMainPage () {
      const pageNumber = Number(this.page)
      const totalPagesNumber = Number(this.totalPages)
      if (pageNumber === 0 || pageNumber < totalPagesNumber) {
        return BlogMainPage
      }
      return NotFound
    }
  },
  async serverPrefetch () {
    await Promise.all([
      this.loadCategories(),
      this.loadArticles({
        category: this.category,
        tags: this.tags,
        query: this.query,
        page: this.initialPage,
        size: this.DEFAULT_ARTICLES_PAGE_SIZE
      })
    ])
  },
  methods: {
    ...mapActions({
      loadArticles: 'blog/loadArticles',
      loadCategories: 'blog/loadCategories'
    })
  },
  async created () {
    if (this.articles.value.length < 1) {
      await Promise.all([
        this.loadCategories(),
        this.loadArticles({
          category: this.category,
          tags: this.tags,
          query: this.query,
          page: this.initialPage,
          size: this.DEFAULT_ARTICLES_PAGE_SIZE
        })
      ])
    }
  }
}
