import { mapActions, mapGetters, mapState } from 'vuex'
import clickOutside from 'src/directives/lib/clickOutside.js'

export default {
  directives: {
    clickOutside
  },
  data () {
    return {
      showMore: false,
      showModal: false
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    }),
    ...mapState({
      categories: state => state.blog.categories
    }),
    blogPageUrl () {
      return `${this.config.self.url}${this.getLanguageUrlPathPrefix()}/blog`
    },
    categoryItems () {
      if (this.categories.isLoading) {
        return []
      }
      return this.categories.value.filter(e => e.showOnLanding)
    },
    dropDownCategories () {
      if (this.categories.isLoading) {
        return []
      }
      return this.categories.value.filter(e => !e.showOnLanding)
    },
    facebookLink () {
      return 'https://www.facebook.com/timex.io'
    },
    telegramLink () {
      return 'https://t.me/timexio'
    },
    instagramLink () {
      return 'https://instagram.com/timex.io'
    },
    twitterLink () {
      return 'https://twitter.com/ExchangeTimex'
    }
  },
  methods: {
    ...mapActions({
      loadArticles: 'blog/loadArticles'
    }),
    getCurrentCategoryClass (category) {
      return category === this.$route.params.category ? 'active' : ''
    },
    categoryUrl (category) {
      const result = new URL(this.blogPageUrl + `/category-${category}`)
      return result.href
    },
    async handleNavigation ({ selectCategory }) {
      let newPath = null
      let newCategory = null

      newCategory = selectCategory
      newPath = `${this.getLanguageUrlPathPrefix()}/blog/category-${selectCategory}/`
      await this.loadArticles({
        category: newCategory
      })

      if (this.showModal === true) {
        this.showModal = false
      }

      if (this.$route.fullPath !== newPath) {
        await this.$router.push({
          path: newPath
        })
      }
    }
  },
  mounted () {
  }
}
