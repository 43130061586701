import VueTypes from 'vue-types'
import uniqid from 'uniqid'

export default {
  name: 'base-field',
  props: {
    label: String,
    placeholder: String,
    type: String,
    underlineText: String,
    underlineType: VueTypes.oneOf(['error', 'info', 'normal']).def('error'),
    required: Boolean,
    value: VueTypes.oneOfType([
      String,
      Number
    ]),
    disabled: Boolean,
    theme: VueTypes.oneOf(['normal', 'dark', 'light', 'transparent', 'modern']).def('normal'),
    validateInputHandler: Function,
    keypresshandler: Function,
    keypressenterhandler: Function,
    blurHandler: Function,
    dotted: Boolean,
    dashed: Boolean,
    readonly: Boolean
  },
  data () {
    return {
      key: uniqid(),
      notEmpty: this.value !== '' && this.value != null
    }
  },
  watch: {
    value (v) {
      this.notEmpty = v !== '' && v != null
    }
  },
  methods: {
    keyPressHandler (event) {
      this.keypresshandler && this.keypresshandler(event)
    },
    keyPressEnterHandler (event) {
      this.keypressenterhandler && this.keypressenterhandler(event)
    },
    blurHandlerFn (event) {
      this.blurHandler && this.blurHandler(event)
    },
    onInputHandler (event) {
      this.validateInputHandler && this.validateInputHandler(event)
      this.$emit('input', event.target.value)
    },
    focus () {
      this.$refs.baseFieldInput.focus()
    }
  }
}
