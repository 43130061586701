import { mapActions, mapGetters } from 'vuex'
import format from 'date-fns/format'

export default {
  components: {
  },
  props: {
    id: String,
    createdAt: Date,
    title: String,
    seo: Object,
    metainfo: Object,
    preview: Object,
    image: String,
    imageAlt: String,
    author: Object,
    simpleMode: Boolean
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    }),
    blogUrlPrefix () {
      return `${this.config.self.url}${this.getLanguageUrlPathPrefix()}/blog`
    },
    articleUrl () {
      return `${this.blogUrlPrefix}/${this.metainfo.url}`
    },
    categoryUrl () {
      const result = new URL(`${this.blogUrlPrefix}/category-${this.metainfo.categories.name}`)
      return result.href
    }
  },
  methods: {
    ...mapActions({
      loadArticles: 'blog/loadArticles',
      loadArticle: 'blog/loadArticle'
    }),
    formatDate (dateString) {
      return format(new Date(dateString), 'dd.MM.yyyy')
    },
    async handleArticle (url) {
      const path = `/blog/${url}`
      await this.loadArticle({ url: url })

      if (this.$route.fullPath !== path) {
        await this.$router.push({
          path: path
        })
      }
    },
    async handleNavigation ({ selectCategory, articleUrl }) {
      let newPath = null
      let newCategory = null

      if (selectCategory) {
        newCategory = this.metainfo.categories.name
        newPath = `${this.getLanguageUrlPathPrefix()}/blog/category-${this.metainfo.categories.name}/`
        await this.loadArticles({
          category: newCategory
        })
      } else if (articleUrl) {
        newPath = `${this.getLanguageUrlPathPrefix()}/blog/${articleUrl}`
        await this.loadArticle({ url: articleUrl })
      }

      if (this.$route.fullPath !== newPath) {
        await this.$router.push({
          path: newPath
        })
      }
    }
  }
}
